import React, { useState } from "react";
import { Button, Col, Container, Row, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./PopularCourse.css";
import PopularCard from "../../GlobalComponent/PopularCard/PopularCard";
import datanalysis from "../../Assets/datanalysis.png";
import python from "../../Assets/python.png";
import ai from "../../Assets/ai.jpeg";
import ml from "../../Assets/ml.png";
import sql from "../../Assets/sql.jpeg";
import generateai from "../../Assets/gen.jpeg";

function PopularCourse() {
  const pagedata = [
    {
      id: 1,
      title: "NASSCOM Certified ML & GenAI Course",
      title2:
        "This Course Guarantees Placement and Equips you with cutting-edge skills in data science and generative AI. By the end, you'll be capable of creating applications like ChatGPT.",
      curriculum: "CodeAIxpert Machine Learning and Generative AI Course",
      mod1: "- Effective Googling and AI tool utilization - Fundamental of Programming - Git concepts - Linux fundamentals - Python fundamentals - Advance Python for DataScience",
      mod2: "- SQL - Advanced Excel - Exploratory Data Analysis - Linear algebra - Probability and Statistics - Tableau",
      mod3: "- Fundamental of Machine Learning - Supervised Learning Algorithms - Capstone projects - 1",
      mod4: "- Unsupervised learning - Recommendation Systems - Capstone project - 2 - Deep learning - CNN - RNN, LSTM and GPTs - Capstone project - 3",
      mod5: "- Fundamental of Generative AI - Application development fundamentals - APIs for Generative AI models - Retrieval Augmented Generation - Fundamental of Vector DB - Finetuning and PEFT - Generative AI Project",
      mod6: "- Network concepts - VMs - Docker - AWS - Productionisation and deployment of ML models Internship of 3 months with real time industry projects Course includes 10 Tests and 10 Assignments as a part of the course curriculum.",
      price: "Rs. 59,999/-",
      imageSrc: ai,
      placement: "true",
    },
    {
      id: 2,
      title: "Python Basic to Advanced",
      title2:
        "This course hones your Python skills, covering every aspect needed to become a proficient Python developer.",
      curriculum: "Python Basic to Advanced",
      mod1: "Fundamnetal of Python programming",
      mod2: "Control flow and Functions",
      mod3: "Python Data Structures",
      mod4: "Object Oriented Programming",
      mod5: "Introduction to NumPy and Pandas",
      mod6: "Data Visualization with Matplotlib",
      price: "Rs. 4,999/-",
      imageSrc: ("https://plus.unsplash.com/premium_photo-1678565999332-1cde462f7b24?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"),
      placement: "false",
    },
    {
      id: 3,
      title: "Structure Query Language",
      title2: "This course hones your sql skills, covering every aspect needed to become a proficient DBA.",
      curriculum: "Structure Query Language",
      mod1: "Introduction to SQL and Database Setup",
      mod2: "Basic SQL Queries",
      mod3: "Filtering and Logical Operations",
      mod4: "Aggregate Functions and Grouping",
      mod5: "Joins and Subqueries",
      mod6: "Data Manipulation and Control",
      price: "Rs. 4,999/-",
      imageSrc: sql,
      placement: "false",
    },
    {
      id: 4,
      title: "Data Analysis",
      title2: "This course hones your data analysis skills, covering every aspect needed to become a proficient data analyst.",
      curriculum: "Data Analysis",
      mod1: "SQL",
      mod2: "Advanced Excel",
      mod3: "Exploratory Data Analysis",
      mod4: "Linear Algebra",
      mod5: "Probability and Statistics",
      mod6: "Tableau",
      price: "Rs. 9,999/-",
      imageSrc: ("https://plus.unsplash.com/premium_photo-1661878265739-da90bc1af051?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"),
      placement: "false",
    },
    {
      id: 5,
      title: "Generative AI Course",
      title2: "This course hones your generative ai skills, covering every aspect needed to become a proficient GEN AI engineer.",
      curriculum: "Generative AI Course",
      mod1: "Fundamentals of Generative AI",
      mod2: "Application Developement",
      mod3: "APIs for GenAI Models",
      mod4: "Retrieval Augmented Generation",
      mod5: "Fundamentals of Vector DB",
      mod6: "Finetuning and PEFT",
      price: "Rs. 9,999/-",
      imageSrc: generateai,
      placement: "false",
    },
    {
      id: 6,
      title: "Machine Learning Basic to Advanced",
      title2: "This course hones your machine learning skills, covering every aspect needed to become a proficient ML engineer.",
      curriculum: "Machine Learning Basic to Advanced",
      mod1: "Fundamental of Machine Learning",
      mod2: "Supervised Learning Algorithms",
      mod3: "Unsupervised Learning",
      mod4: "Recommendation Systems",
      mod5: "Deep Learning, CNN",
      mod6: "RNN & LSTM, GPTs",
      price: "Rs. 9,999/-",
      imageSrc: ml,
      placement: "false",
    },
  ];

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (course) => {
    setSelectedCourse(course);
    setShow(true);
  };

  const handleTermsClose = () => setShowTermsModal(false);
  const handleTermsShow = () => {
    setShowTermsModal(true);
    setShow(false); // Close the previous modal
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleRedirect = () => {
    if (isChecked) {
      window.location.href = "https://razorpay.me/@codeaixpert"; // Redirect to the Razorpay payment page
    }
  };

  const goToTermsAndConditions = () => {
    handleTermsClose(); // Close the modal before navigating
    navigate("/terms-and-conditions"); // Replace with your actual route
  };

  return (
    <>
      <Container fluid className="most-line-container">
        <Row>
          <Col className="most-line">
            Most <span className="popular-line"> Popular Course</span>
          </Col>
        </Row>
      </Container>
      <Container className="popular-course-container" id="courses">
        <Row className="various-version-row"></Row>
        <Row className="second-carddd-roww">
          {pagedata.map((course) => (
            <Col
              key={course.id}
              md={4}
              xs={12}
              lg={4}
              style={{ display: "flex", justifyContent: "center" }}
              onClick={() => handleShow(course)}
            >
              <PopularCard
                imageSrc={course.imageSrc} 
                title={course.title}
                price={course.price}
                text={course.title2}
                placement={course.placement}
               
              />
            </Col>
          ))}
        </Row>
        {selectedCourse && (
          <Modal
            show={show}
            onHide={handleClose}
            className="custom-modal"
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Course Curriculum: {selectedCourse.curriculum}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
              <div className="divoo">
                <span className="rr">MODULE 1:</span> {selectedCourse.mod1}
              </div>
              <div className="divoo">
                <span className="rr">MODULE 2:</span> {selectedCourse.mod2}
              </div>
              <div className="divoo">
                <span className="rr">MODULE 3:</span> {selectedCourse.mod3}
              </div>
              <div className="divoo">
                <span className="rr">MODULE 4:</span> {selectedCourse.mod4}
              </div>
              <div className="divoo">
                <span className="rr">MODULE 5:</span> {selectedCourse.mod5}
              </div>
              <div className="divoo">
                <span className="rr">MODULE 6:</span> {selectedCourse.mod6}
              </div>
            </Modal.Body>
            <Modal.Footer
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" onClick={handleTermsShow}>
                Purchase
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Modal
          show={showTermsModal}
          onHide={handleTermsClose}
          className="custom-modal"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>CodeaiXpert Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <i>
              Thank you for your purchase! Please send a screenshot of your
              payment receipt to <strong>business@codeaixpert.com</strong>.
              You'll receive access to the course within 24 hours of payment. We
              wish you a fantastic learning experience and much success in your
              journey with us.
            </i>

            <Form.Check
              type="checkbox"
              label={
                <span>
                  I accept the{" "}
                  <a href="#" onClick={goToTermsAndConditions}>
                    terms and conditions
                  </a>
                </span>
              }
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </Modal.Body>
          <Modal.Footer
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button variant="danger" onClick={handleTermsClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleRedirect}
              disabled={!isChecked}
            >
              Proceed to Payment
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default PopularCourse;
