import React from 'react';
import './Terms.css'; 

const Terms = () => {
  return (
    <div className="terms-conditions-container">
      <h1>CodeaiXpert Terms and Conditions</h1>

      <section className="section">
        <h2>Acceptance of Terms</h2>
        <p>
          By accessing and using CodeaiXpert, you agree to comply with these terms and conditions, which are governed by the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>Course Enrollment</h2>
        <p>
          Enrollment is confirmed upon receipt of payment. You are responsible for providing accurate information during registration.
        </p>
      </section>

      <section className="section">
        <h2>Payment and Fees</h2>
        <p>
          Course fees are payable in full at the time of enrollment or through an EMI plan. GST is non-refundable, as per the Goods and Services Tax Act.
        </p>
      </section>

      <section className="section">
        <h2>Refund Policy</h2>
        <p>
          Refunds are available as per our policy, which complies with the provisions set forth in the Bharatiya Nyaya Sanhita. Please refer to our refund policy for detailed terms.
        </p>
      </section>

      <section className="section">
        <h2>Intellectual Property</h2>
        <p>
          All course materials are the intellectual property of CodeaiXpert. Unauthorized use, distribution, or reproduction is prohibited.
        </p>
      </section>

      <section className="section">
        <h2>User Conduct</h2>
        <p>
          Users must use the platform responsibly, respecting the rights of others. Any form of abuse, harassment, or illegal activity is strictly prohibited.
        </p>
      </section>

      <section className="section">
        <h2>Course Content</h2>
        <p>
          CodeaiXpert reserves the right to modify course content, instructors, and schedules without prior notice.
        </p>
      </section>

      <section className="section">
        <h2>Account Security</h2>
        <p>
          You are responsible for maintaining the confidentiality of your account information. Notify us immediately if you suspect any unauthorized use.
        </p>
      </section>

      <section className="section">
        <h2>Limitation of Liability</h2>
        <p>
          CodeaiXpert is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services, as per the liability limitations outlined in the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>Termination</h2>
        <p>
          We reserve the right to terminate your access to the platform for violations of these terms, in accordance with the provisions of the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>Governing Law</h2>
        <p>
          These terms are governed by the laws of [Insert Jurisdiction], and any disputes will be resolved under the jurisdiction of the courts in this region, as per the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>Amendments</h2>
        <p>
          We may update these terms from time to time. Continued use of the platform signifies acceptance of the revised terms.
        </p>
      </section>
    </div>
  );
};

export default Terms;
