import React, { useState } from "react";

const FeedbackCard = ({ name, title, image, image1, para }) => {
  const [showMore, setShowmore] = useState(false);

  const handleShowMore = () => {
    setShowmore(!showMore);
  };

  return (
    <div className="Feedback-card">
      <div>
        <div className="Feedback-card-header">
          <div className="Feedback-card-left-div">
            <img src={image} alt="loading..." className="Feedback-card-img" />
            <div className="Feedback-div">
              <div className="Feedback-name">{name} </div>
              <div className="Feedback-title">{title} </div>
            </div>
          </div>
          <div className="vector-div">
            <img
              src={image1}
              alt="loading..."
              className="Feedback-card-vector"
            />
          </div>
        </div>
      </div>
      <div className="Feedback-card-para">
        {(showMore ? para : para?.slice(0, 265)) || "N/A"}
        <span className="show-more-text" onClick={handleShowMore}>
          {showMore ? null : para.length >= 295 ? "Show More" : null}{" "}
        </span>
      </div>
    </div>
  );
};

export default FeedbackCard;
