import React from "react";
import PopularCourse from "../PopularCourse/PopularCourse";
import StudentPage from "../../GlobalComponent/StudentPage/StudentPage";
import FeedbackPage from "../../GlobalComponent/FeedbackPage/FeedbackPage";
import ContactUs from "../ContactUs/ContactUs";
import Community from "../Community/Community";
import HomePage from "../HomePage/HomePage";
import "./MainComponent.css";
import MarqueeComponent from "../Marqueue/MarqueeComponent";
import OurTeam from "../OurTeam/OurTeam";
import Skills from "../Skills/Skills";

export default function MainComponent() {
  return (
    <div className="AllComponentContainers">
      <HomePage />
      <MarqueeComponent />
      <PopularCourse />
      <Skills />
      <StudentPage />
      <FeedbackPage />
      <OurTeam />
      <ContactUs />
      <Community />
    </div>
  );
}
