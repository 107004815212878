import React, { useState, useEffect } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import "./PopularCard.css";

function PopularCard({ title, text, imageSrc, price, imageHeight, placement }) {
  const [isLoading, setIsLoading] = useState(true);
 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Card className={`card-contain ${isLoading ? "skeleton" : ""}`} >
        <Row>
          {isLoading ? (
            <div className="skeleton-image" style={{ height: imageHeight }} />
          ) : (
            <Image src={imageSrc} style={{ height: "13rem" }} loading="lazy" />
          )}
        </Row>
        <Card.Body style={{ padding: "0", paddingTop: "1rem", paddingRight: "0" }} >
          <Card.Title className={`machine-learning ${isLoading ? "skeleton-text" : ""}`}>
            {isLoading ? "" : title}
          </Card.Title>
          <Card.Text className={`codeaixperrt ${isLoading ? "skeleton-text" : ""}`}>
            {isLoading ? "" : text}
          </Card.Text>

         
        </Card.Body>
        <Row style={{ paddingLeft: "0.7rem", paddingRight: "0.7rem", marginTop: "0.3rem" }}>
            <hr className="hr-classss" />
          </Row>
          <Row>
            <Col className={`rs-499 ${isLoading ? "skeleton-text" : ""}`} sm={5} xs={5}>
              {isLoading ? "" : price}
            </Col>
            <Col className="arrow-class-btn" sm={7} xs={7}>
              {!isLoading && placement === "true" && (
                <Button className="aroow-icooon-btn" >
                  Guaranteed Placement
                </Button>
              )}
            </Col>
          </Row>
      </Card>
  
    </> 
  );
}

export default PopularCard;