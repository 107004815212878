import React, { useState } from "react";
import "./ContactUs.css";
import { Col, Row } from "react-bootstrap";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoArrowDownOutline } from "react-icons/io5";

const ContactUs = () => {
  const [showContent, setShowContent] = useState(false);
  const [secondContainedShow, setsecondContainedShow] = useState(false);
  const [ThirdContainedShow, setThirdContainedShow] = useState(false);

  const toggleShow = () => {
    setShowContent(!showContent);
  };
  const SecondtoggleShow = () => {
    setsecondContainedShow(!secondContainedShow);
  };
  const ThirdtoggleShow = () => {
    setThirdContainedShow(!ThirdContainedShow);
  };

  const handleToContactUs = (path) => {
    const getId = document.getElementById("contactUsId");
    if (getId) {
      getId.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="container-background-image" id="faq">
      <div className="form-container">
        <Row>
          <Col xs={10} sm={11}>
            <div className="contactUsquestions CurSor" onClick={toggleShow}>
              How is the Job Guarantee possible?
            </div>
          </Col>
          <Col
            xs={2}
            sm={1}
            className="arrow-icon-container"
            onClick={toggleShow}
          >
            {showContent ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </Col>
        </Row>
        <div className={`content-container ${showContent ? "show" : "hide"}`}>
          <div className="conatactUsanswer">
            There is a growing demand for AI, Machine Learning, and Data Science
            professionals, with thousands of open positions across various
            industries. Our course, built around real-world projects, ensures
            you gain practical and applicable skills, resulting in a strong
            portfolio. Confident in our comprehensive content, we offer a job
            guarantee or a moneyback program, aligning our success with yours.
          </div>
        </div>
        <div className="horizontal-line"></div>

        <Row>
          <Col xs={10} sm={11}>
            <div className="contactUsquestions CurSor" onClick={SecondtoggleShow}>
              What is the Job Guarantee Process?
            </div>
          </Col>
          <Col
            xs={2}
            sm={1}
            className="arrow-icon-container"
            onClick={SecondtoggleShow}
          >
            {secondContainedShow ? (
              <MdKeyboardArrowUp />
            ) : (
              <MdKeyboardArrowDown />
            )}
          </Col>
        </Row>
        <div
          className={`content-container ${
            secondContainedShow ? "show" : "hide"
          }`}
        >
          <div className="conatactUsanswer">
            After completing course assignments, each candidate is assigned a
            personal mentor for placement. The mentor provides guidance on
            portfolio and resume building, interview preparation, and conducts
            mock interviews. Candidates must complete at least two capstone
            projects under the mentor's guidance to build a strong portfolio.
            Upon completing the CodeaiXpert ML and genAI course and all
            assignments, candidates become eligible for our job guarantee
            program. We then forward their portfolios to our partner companies,
            ranging from startups to Fortune 500 companies, based on the
            strength of the candidate's portfolio.
          </div>
        </div>
        <div className="horizontal-line"></div>

        <Row>
          <Col xs={10} sm={11}>
            <div className="contactUsquestions CurSor" onClick={ThirdtoggleShow}>
              What are the Job Guarantee Terms and Conditions?
            </div>
          </Col>
          <Col
            xs={2}
            sm={1}
            className="arrow-icon-container"
            onClick={ThirdtoggleShow}
          >
            {ThirdContainedShow ? (
              <MdKeyboardArrowUp />
            ) : (
              <MdKeyboardArrowDown />
            )}
          </Col>
        </Row>
        <div
          className={`content-container ${
            ThirdContainedShow ? "show" : "hide"
          }`}
        >
          <div className="conatactUsanswer">
            To be eligible for our Job Guarantee or moneyback program, you must
            complete and submit all course assignments within the duration of
            course without any plagiarism. You must have attended the live class
            with 85% of attendance or should have finished watching 100% videos.
            You should appear in all the mock interviews scheduled for you as a
            part of course work.
          </div>
        </div>
        <div className="horizontal-line"></div>

        <div className="buttonmainContainer">
          <div className="buttonContainer">
            <div className="contsactUsQuestionContainer">
              <div className="contsactUsQuestion">My question is not here.</div>
            </div>
            <div className="ConatctUsButton " onClick={handleToContactUs}>
              <div className="ConatctUsButtontext">Contact Us</div>
              <IoArrowDownOutline style={{ color: "black" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
