import React from 'react';
import './Cancellation.css'; // Import a CSS file for styling

const Cancellation = () => {
  return (
    <div className="refund-policy-container">
      <h1>CodeaiXpert Refund Policy</h1>
      
      <section className="section">
        <h2>Our Commitment</h2>
        <p>
          At CodeaiXpert, we are dedicated to providing high-quality AI education. We understand that our students' satisfaction is paramount, and we are committed to ensuring a positive learning experience. To that end, we offer a 100% refund of the course fee if you are not satisfied with the course, in accordance with the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>Refund Details</h2>
        <div>
          <div><strong>Course Price (Without GST):</strong> ₹49,200</div>
          <div><strong>GST (IGST):</strong> 18% amounting to ₹10,800</div>
          <div><strong>Total Course Price:</strong> ₹60,000 (inclusive of IGST)</div>
          <div><strong>EMI Option:</strong> ₹8,200 per month for 6 months.</div>
        </div>
      </section>

      <section className="section">
        <h2>Eligibility for Refund</h2>
        <h3>Satisfaction Guarantee</h3>
        <p>
          If you are not satisfied with the course content, learning experience, or any aspect of the program, you are eligible for a full refund of the course fee (exclusive of GST) within 30 days of completing the course, as per Section 72 of the Bharatiya Nyaya Sanhita.
        </p>

        <h3>Refund Coverage</h3>
        <p>
          The refund policy covers the course price excluding GST. The total amount of ₹49,200 will be refunded. GST paid is non-refundable as it is remitted to the government, in accordance with the Goods and Services Tax Act.
        </p>

        <h3>EMI Considerations</h3>
        <p>
          If you have opted for the EMI option, the refund will include the sum of all payments made towards the course fee up to the date of the refund request, excluding any interest or fees charged by your financial institution, as per the terms of the EMI agreement.
        </p>
      </section>

      <section className="section">
        <h2>Refund Process</h2>
        <h3>Initiation</h3>
        <p>
          To request a refund, please reach out to our support team at [insert contact information] with your course details and a brief explanation of why the course did not meet your expectations.
        </p>

        <h3>Verification</h3>
        <p>
          Upon receiving your request, our team will review the details to ensure eligibility for the refund. You may be asked to provide feedback or complete an exit survey to help us improve our offerings.
        </p>

        <h3>Processing</h3>
        <p>
          Once the refund is approved, it will be processed within 7-10 business days. The refund will be issued to the original payment method used at the time of purchase.
        </p>
      </section>

      <section className="section">
        <h2>Commitment to Quality</h2>
        <p>
          At CodeaiXpert, we continuously strive to improve our courses and offer the best learning experience possible. Your feedback is invaluable to us, and we welcome any suggestions or concerns you may have throughout your learning journey.
        </p>
        <p>
          We are confident that our AI course will meet your expectations, but we also believe in giving our students the peace of mind that comes with a satisfaction guarantee. Your investment in your education is important, and we are here to support you every step of the way.
        </p>
      </section>
    </div>
  );
};

export default Cancellation;
