// import React from "react";
// import "./Skills.css";
// import { Col, Container, Row } from "react-bootstrap";

// const skillsData = [
//   {
//     img: "https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=2062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     name: "Python",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=2062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     name: "Python",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=2062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     name: "Python",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=2062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     name: "Python",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=2062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     name: "Python",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=2062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     name: "Python",
//   },
//   {
//     img: "https://images.unsplash.com/photo-1649180556628-9ba704115795?q=80&w=2062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     name: "Python",
//   },
// ];

// const Skills = () => {
//   return (
//     <>
//       <Container className="">
//         <Row>
//           <Col md={12} className="team-first-col">
//             <h1 className="our-team-containt">
//               Skills You’ll <span className="team-first-col-span">Learn</span>
//             </h1>
//           </Col>
//         </Row>
//         <Row className="teamRowimages">
//           {skillsData.map((item, index) => (
//             <Col md={2} xs={3} key={index} style={{ textAlign: "center" }}>
//               <div className="image-container-text">
//                 <img
//                   src={item.img}
//                   alt={item.name}
//                   className="our-team-photo"
//                 />
//                 <h5>{item.name}</h5>
//               </div>
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     </>
//   );
// };

// export default Skills;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Skills.css";
import python from "../../loogo/python.PNG";
import numpy from "../../loogo/numpy.PNG";
import pandas from "../../loogo/pandas.png";
import seaborn from "../../loogo/seaborn1.PNG";
import matplotlib from "../../loogo/matplotlib.PNG";
import scikit from "../../loogo/scikit.png";
import tensorflow from "../../loogo/tensorflow1.png";
import keras from "../../loogo/keras.PNG";
import git from "../../loogo/git.png";
import github from "../../loogo/github.png";
import linux from "../../loogo/linux.png";
import excel from "../../loogo/excel.png";
import sql from "../../loogo/sql.jpg";
import tableau from "../../loogo/teblau.png";
import flask from "../../loogo/flask.png";
import waevaite from "../../loogo/weviate.png";
import langchain from "../../loogo/lanchain.png";
import kubernetes from "../../loogo/kubernetes.png";
import docker from "../../loogo/docker.png";
import aws from "../../loogo/aws.png";
import mlflow from "../../loogo/mlflow.png";
import ray from "../../loogo/ray.png";
import o11 from "../../loogo/openai.webp";
import hugg from "../../loogo/hugg.png";


const skillsData = [
  {
    img: python,
    name: "Python",
  },
  {
    img: numpy,
    name: "Numpy",
  },
  {
    img:pandas,
    name: "Pandas",
  }
  ,
  {
    img:seaborn,
    name: "Seaborn",
  }
  ,
  { 
    img:matplotlib,
    name: "Matplotlib",
  },
  {
    img:scikit,
    name: "Scikit-learn",
  }
  ,
  {
    img:flask,
    name: "Flask",
  }
  ,
  {
    img:tensorflow,
    name: "Tensorflow",
  },
  {
    img:keras,
    name: "Keras",
  },
  {
    img:git,
    name: "Git",
  }
  ,
  {
    img:github,
    name: "Github",
  }
  ,
  {
    img:linux,
    name: "Linux",
  }
  ,
  {
    img:excel,
    name: "Excel",
  }
  ,
  {
    img:sql,
    name: "SQL",
  }
  ,
  {
    img:tableau,
    name: "Tableau",
  }
  ,
  {
    img: o11,
    name: "OpenAI",
  }
  ,
  {
    img:waevaite,
    name: "Weaviate",
  }
  ,
  {
    img:langchain,
    name: "Langchain",
  },
  {
    img:hugg,
    name: "HuggingFace",
  },
  
  {
    img:docker,
    name: "Docker",
  }
  ,
  {
    img:kubernetes,
    name: "Kubernetes",
  }
  ,
  {
    img:aws,
    name: "AWS",
  }
  ,
  {
    img:mlflow,
    name: "MLflow",
  }
  ,
  {
    img:ray,
    name: "RAY",
  }
];

const Skills = () => {
  return (
    <><Container fluid className="team-first-col-container"><Row>
    <Col md={12} className="team-first-col">
      <h1 className="our-team-containt">
        Skills You’ll <span className="team-first-col-span">Learn</span>
      </h1>
    </Col>
  </Row></Container>
    <Container  className="skills-container">

      <Row className="justify-content-center">
        {skillsData.map((item, index) => (
          <Col key={index} xs={3} md={2} lg={1} className="text-center mb-4">
            <div className="skill-item">
              <div className="skill-image-container">
                <img src={item.img} alt={item.name} className="skill-image" />
              </div>
              <h5 className="skill-name">{item.name}</h5>
            </div>
          </Col>
        ))}
      </Row>
    </Container></>
  );
};

export default Skills;
