import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ailogo from "../../Assets/Group 1321316268 (1).png";
import lastimg from "../../Assets/Frame 1321316299.png";
import { SlSocialFacebook } from "react-icons/sl";
import { AiOutlineYoutube } from "react-icons/ai";
import { LuTwitter } from "react-icons/lu";
import { SiInstagram } from "react-icons/si";
import brandNewFavicon from "../../Assets/Homepagelogo/NewAiBrandLogo.png";
import "./Footer.css";
import OptimizedImage from "../../GlobalComponent/OptimizedImage/OptimizedImage";

export default function Footer() {
  const navigate = useNavigate();

  
  return (
    <>
      <Container className="FooterContainer">
        <Row style={{ textAlign: "center", alignItems: "center" }}>
          <Col></Col>
          <Col md={4}>
            <Row>
              <Col>
                <Image src={brandNewFavicon} className="ailogooooo" />
              </Col>
            </Row>
            <Row>
              <Col className="footerrcoll">CodeaiXpert</Col>
            </Row>
            <Row className="cdk-rowss">
              <Col className="cdk-sol-pvt">
                CodeKart Solutions Private Limited Phase-1, Saraswati Vihar,
                Duplex Number-2, Backside of Mani Tribhuvan Bhubaneswar, Odisha
                751024, <br /> info@codeaixpert.com <br />
                +91 6372249766
              </Col>
            </Row>
            <Row className="iconns-rows">
              <Col>
                <div className="iconsss-div">
                  <AiOutlineYoutube className="icon-youtube" />
                </div>
              </Col>
              <Col>
                <div className="iconsss-div">
                  <SlSocialFacebook className="icon-facebook" />
                </div>
              </Col>
              <Col>
                <div className="iconsss-div">
                  <LuTwitter className="icon-facebook" />
                </div>
              </Col>
              <Col>
                <div className="iconsss-div">
                  <SiInstagram className="icon-facebook" />
                </div>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <div className="lastimage-container8144">
        <OptimizedImage src={lastimg} className="lastimagee" />

        <Container className="last-texttttt">
          <Row className="last-textttt">
            <Col lg={7}>
              <span onClick={() => navigate('/privacy')} >Privacy Policy </span>
              <span onClick={() => navigate('/terms')}>Terms and Conditions </span>
              <span onClick={() => navigate('/cancellation')}>Cancellation and Refund </span>
              <span onClick={() => navigate('/shipping')}>Shipping and Delivery</span>
            </Col>
            <Col lg={5}>
              {" "}
              © 2024 All Rights Reserved by CodeKart Solutions Private Limited
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
