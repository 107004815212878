import React from 'react';
import './Shipping.css'; // Import a CSS file for styling

const Shipping = () => {
  return (
    <div className="shipping-delivery-container">
      <h1>CodeaiXpert Shipping and Delivery Policy</h1>

      <section className="section">
        <h2>Introduction</h2>
        <p>
          At CodeaiXpert, we do not sell physical products. We offer educational services through online live classes. As our services are delivered digitally, there are no shipping or delivery processes involved.
        </p>
      </section>

      <section className="section">
        <h2>Course Access</h2>
        <p>
          All course materials, resources, and classes are accessible directly through our online platform once you have completed your enrollment. You can engage with the content at your convenience, ensuring a flexible learning experience.
        </p>
      </section>

      <section className="section">
        <h2>Support and Inquiries</h2>
        <p>
          If you have any questions regarding access to your course materials or live sessions, please contact our support team at [insert contact details]. We are here to assist you and ensure you have a smooth learning journey.
        </p>
      </section>

      <p>Thank you for choosing CodeaiXpert for your online learning journey. Your satisfaction is our priority, and we are committed to providing you with high-quality educational services.</p>
    </div>
  );
};

export default Shipping;
