import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./StudentPage.css";
import StudentCard from "./StudentCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function StudentPage() {
  const studentData = [
    {
      name: "Chinmoyee Mohapatra",
      title: "Software Engineer@ServiceNow",
      image: require("../../Assets/StudentsImage/student-1.jpg"),
    },
    {
      name: "Deme Sai Kiran",
      title: "Sr Software Engineer@Samsung",
      image: require("../../Assets/StudentsImage/student-3.jpeg"),
    },
    {
      name: "Priyanka Gour",
      title: "Asst. prof @Vignan University",
      image: require("../../Assets/StudentsImage/student-4.jpeg"),
    },
    {
      name: "Harshita Kathuria",
      title: "ML Engineer@One Convergence",
      image: require("../../Assets/StudentsImage/harsita.jpg"),
    },
    {
      name: "Mahitha Kodali",
      title: "Data Scientist@ADP",
      image: require("../../Assets/StudentsImage/moohita.jpg"),
    },
    {
      name: "Bhabani Sankar Khuntia",
      title: "Software Engineer@TCS",
      image: require("../../Assets/StudentsImage/bhabani.jpg"),
    },
    {
      name: "Swati Monalisha Patra",
      title: "Software Developer@LawSikho",
      image: require("../../Assets/StudentsImage/monalisha.jpg"),
    },
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Container fluid className="students-container" id="success">
        <Row>
          <Col md={12} className="students-first-col">
            Meet Our <span className="students-first-col-span">Successful</span>{" "}
            Students
          </Col>
          <Col md={12} className="students-second-col">
            Below you'll find our students who are working on the lead tech
            companies
          </Col>
        </Row>
        <Container>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            showDots={true}
            // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            className="student-carousel"
          >
            {studentData.map((student, index) => (
              <StudentCard
                key={index}
                name={student.name}
                title={student.title}
                image={student.image}
              />
            ))}
          </Carousel>
        </Container>
      </Container>
    </>
  );
}

export default StudentPage;
