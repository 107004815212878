import React from "react";
import { Container } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import ajio from "../../Assets/ajio.png";
import atosh from "../../Assets/atosh.png";
import Dell from "../../Assets/Dell.png";
import ey from "../../Assets/ey.png";
import intel from "../../Assets/intel.png";
import oracle from "../../Assets/oracle.png";
import progress from "../../Assets/progress.png";
import tcs from "../../Assets/tcs.png";
import "./MarqueeComponent.css";

export default function MarqueeComponent() {
  return (
    <div>
      <Container fluid className="io">
        <Marquee speed={100} gradient={false} className="marq">
          <div>
            <img src={atosh} alt="" className="imgo" />
          </div>{" "}
          <div>
            <img
              src={ajio}
              alt=""
              className="imgo1245"
              //   style={{ height: "5rem", width: "4rem" }}
            />
          </div>
          <div>
            <img
              src={Dell}
              alt=""
              className="imgo123"
              //   style={{ height: "4rem", width: "4rem" }}
            />
          </div>{" "}
          <div>
            <img src={oracle} alt="" className="imgo12" />
          </div>{" "}
          <div>
            <img
              src={intel}
              alt=""
              className="imgo"
              //   style={{ height: "2rem", width: "5rem" }}
            />
          </div>{" "}
          <div>
            <img src={tcs} alt="" className="imgo12" />
          </div>{" "}
          <div>
            <img src={progress} alt="" className="imgo12" />
          </div>{" "}
          <div>
            <img
              src={ey}
              alt=""
              className="imgo234"
              //   style={{ height: "3rem", width: "3rem" }}
            />
          </div>{" "}
        </Marquee>
      </Container>
    </div>
  );
}
