import React from "react";
import "./OurTeam.css";
import { Col, Container, Row } from "react-bootstrap";
import sagarSuman from "../../Assets/Team/sagar_suman.jpg";
import abhishek from "../../Assets/OurTeam/Abhishek.jpeg";
import Omm from "../../Assets/OurTeam/Omm.jpg";
import deme_img from "../../Assets/StudentsImage/student-3.jpeg";
import vedanta from "../../Assets/OurTeam/vedant.png";
import victoria from "../../Assets/OurTeam/victoria.jpg";

const IndustyExpert = [
  {
    name: "Sagar Suman",
    image: sagarSuman,
    designation: "Sr Software Engineer at Intel",
    achievements:
      "6+ years of software engineering experience including Generative AI ",
  },
  {
    name: "Abhishek Kumar",
    image: abhishek,
    designation: "Software Engineer at Druva",
    achievements: "MTech in CS from IIT Bombay",
  },
  {
    name: "Deme Sai Kiran",
    image: deme_img,
    designation: "Sr Software Engineer at Samsung",
    achievements: "MTech in CS from University of Hyderabad",
  },
  {
    name: "Balabhadra Tripathy",
    image:
      "https://media.licdn.com/dms/image/D5603AQGj9Ndl1noeiw/profile-displayphoto-shrink_200_200/0/1720375211241?e=1727308800&v=beta&t=G3ObafSgpvGxNNFiQtOoEchzYk2A-po9RViaM3ong-c",
    designation: "Sr. Manager at IBM",
    // achievements: "MTech in CS from University of Hyderabad",
  },
];
const teamMembers = [
  {
    name: "Adil Rashid",
    image:
      "https://media.licdn.com/dms/image/D4E03AQHEtuNrVKZnqg/profile-displayphoto-shrink_800_800/0/1708501916369?e=1726704000&v=beta&t=PE3d328mNr9tp1RD_ebo7fy40rde_j9xFuqX4UlyeX4",
    designation: "CTO at CodeKart Solution Private Limited",
    achievements: "Successfully delivered 48 project",
  },
  {
    name: "Omm Ranjan Nayak",
    image: Omm,
    designation: "Project Manager",
    achievements: "Ex-BDM at BYJU'S",
  },

  {
    name: "Victoria Nayak",
    image: victoria,
    designation: "Placement Head",
    achievements:
      "5 years of experience ensuring student career success. Over 250+ students placed in top companies",
  },
  {
    name: "Vedant Acharya",
    image: vedanta,
    designation: "Lead Data Scientist",
    achievements:
      "5 years of Software Engineer experience (3 years of generative ai). GATE, NET, NIC exams qualified",
  },
];

const OurTeam = () => {
  return (
    <>
      <Container fluid className="team-first-col-container">
        <Row>
          <Col md={12} className="team-first-col">
            <h1 className="our-team-containt">
              Industry <span className="team-first-col-span">mentors</span>
            </h1>
          </Col>
        </Row>
        <Row className="">
          <Col>
            <h1 className="team-desc">
              Passionate. <span style={{ color: "#0166fe" }}>Proactive.</span>{" "}
              Expert
            </h1>
          </Col>
          {/* <div>
          <div className="team-desc-description">
            We lead with care - our core value - and a shared passion for <br />{" "}
            connecting the world
          </div>
        </div> */}
         
        </Row>
        <Row> <Col>
            <div className="team-desc-description">
              Master AI with Guidance from Leading Industry Professionals.
            </div>
          </Col></Row>
      </Container>
      <Container className="">
        <Row className="teamRowimages">
          {IndustyExpert.slice(0, 4).map((member, index) => (
            <Col sm={3} xs={6} key={index} style={{ textAlign: "center" }}>
              <div className="image-container-text">
                <img
                  src={member.image}
                  alt={member.name}
                  className="our-team-photo"
                />
                <h5>{member.name}</h5>
                <div className="designationContaint">{member.designation}</div>
                <div>{member?.achievements}</div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="teamRowimages">
          <Col sm={3} xs={6} style={{ textAlign: "center" }}></Col>
          {IndustyExpert.slice(4).map((member, index) => (
            <Col sm={3} xs={6} key={index + 4} style={{ textAlign: "center" }}>
              <div className="image-container-text">
                <img
                  src={member.image}
                  alt={member.name}
                  className="our-team-photo"
                />
                <h5>{member.name}</h5>
                <div className="designationContaint">{member.designation}</div>
                <div>{member?.achievements}</div>
              </div>
            </Col>
          ))}
          <Col sm={3} xs={6} style={{ textAlign: "center" }}></Col>
        </Row>
      </Container>
      <Container fluid> <Row>
          <Col md={12} className="team-first-col">
            <h1 className="our-team-containt">
              Our <span className="team-first-col-span">Team</span>
            </h1>
          </Col>
        </Row>
</Container>

      <Container className="OutTeamSecondContainer">
       
        <Row className="">
          <div>
            <h1 className="team-desc">
              Enthusiastic.{" "}
              <span style={{ color: "#0166fe" }}>Initiative.</span> Specialist
            </h1>
          </div>
          <div>
            <div className="team-desc-description">
              We lead with care - our core value - and a shared passion for{" "}
              <br /> connecting the world
            </div>
          </div>
        </Row>
        <Row className="teamRowimages">
          {teamMembers.slice(0, 4).map((member, index) => (
            <Col sm={3} xs={6} key={index} style={{ textAlign: "center" }}>
              <div className="image-container-text">
                <img
                  src={member.image}
                  alt={member.name}
                  className="our-team-photo"
                />
                <h5>{member.name}</h5>
                <div className="designationContaint">{member.designation}</div>
                <div>{member?.achievements}</div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="teamRowimages">
          <Col sm={3} xs={6} style={{ textAlign: "center" }}></Col>
          {teamMembers.slice(4).map((member, index) => (
            <Col sm={3} xs={6} key={index + 4} style={{ textAlign: "center" }}>
              <div className="image-container-text">
                <img
                  src={member.image}
                  alt={member.name}
                  className="our-team-photo"
                />
                <h5>{member.name}</h5>
                <div className="designationContaint">{member.designation}</div>
                <div>{member?.achievements}</div>
              </div>
            </Col>
          ))}
          <Col sm={3} xs={6} style={{ textAlign: "center" }}></Col>
        </Row>
      </Container>
    </>
  );
};

export default OurTeam;
