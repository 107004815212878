import React from 'react';

import './Privacy.css'; 

const Privacy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>CodeaiXpert Privacy Policy</h1>
      
      <section className="section"> 
        <h2>Our Commitment to Your Privacy</h2>
        <p>
          At CodeaiXpert, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our education tech platform, in accordance with applicable laws and regulations, including the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>1. Information Collection</h2>
        <p>
          We collect personal information such as your name, email address, phone number, and payment details when you register for our courses or interact with our platform. Additionally, we may collect usage data, such as your IP address, browser type, and activity on our site, to enhance your user experience. This collection of data is conducted in compliance with the data protection principles outlined in the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>2. Use of Information</h2>
        <p>
          Your personal information is used to provide and improve our services, process transactions, communicate with you about course updates, and for customer support. We may also use your data to personalize your learning experience and for marketing purposes, including sending promotional emails and newsletters, but only with your explicit consent, as required by law.
        </p>
      </section>

      <section className="section">
        <h2>3. Data Sharing</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except when necessary to provide our services, comply with legal obligations, or protect our rights. We may share your data with trusted third-party service providers who assist us in operating our platform, conducting our business, or servicing you, provided these parties agree to keep your information confidential.
        </p>
      </section>

      <section className="section">
        <h2>4. Data Security</h2>
        <p>
          We implement a variety of security measures to protect your personal information, including encryption, secure servers, and regular security audits. However, no method of transmission over the Internet or electronic storage is completely secure, and while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
        </p>
      </section>

      <section className="section">
        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          Our platform uses cookies and similar tracking technologies to improve your browsing experience, analyze site traffic, and understand user behavior. You can control cookie preferences through your browser settings, but please note that disabling cookies may affect the functionality of our site. We comply with the legal requirements regarding cookies as outlined in the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>6. User Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal information stored with us. You can also object to the processing of your data or request data portability. To exercise these rights, please contact us using the details provided below. We will respond to your requests in accordance with the provisions set forth in the Bharatiya Nyaya Sanhita.
        </p>
      </section>

      <section className="section">
        <h2>7. Children's Privacy</h2>
        <p>
          Our platform is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that a child under 13 has provided us with personal data, we will take steps to delete such information promptly.
        </p>
      </section>

      <section className="section">
        <h2>8. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new policy on our site and updating the effective date.
        </p>
      </section>

      <section className="section">
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy or our data practices, please contact us at:
        </p>
        <div>
          <div><strong>Email:</strong> info@codeaixpert.com</div>
          <div><strong>Phone:</strong> 6372249766</div>
          <div><strong>Address:</strong> Codekart Solutions Private Limited, Phase-1, Saraswati Vihar, Duplex Number-2, Backside of Mani Tribhuvan, Bhubaneswar, Odisha 751024</div>
        </div>
        <p>
          We are committed to addressing your concerns and ensuring that your privacy is protected. This policy is designed to give you confidence in how your personal information is handled while using CodeaiXpert's services. Your trust is important to us, and we are dedicated to safeguarding your privacy in compliance with the Bharatiya Nyaya Sanhita.
        </p>
      </section>
    </div>
  );
};

export default Privacy;
