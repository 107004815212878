import React from "react";

const StudentCard = ({ name, title, image }) => {
  return (
    <div className="student-card">
      <img src={image} alt="loading..." className="student-card-img" />
      <div className="student-name">{name} </div>
      <div className="student-title">{title} </div>
    </div>
  );
};

export default StudentCard;
