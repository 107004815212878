import React, { useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import "./Community.css";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import Utility from "../../GlobalComponent/Utils/Toast";

function Community() {
  const [inputes, setInputs] = useState({
    from_name: "",
    from_email: "",
    from_phone: "",
    from_message: "",
  });

  const [touched, setTouched] = useState({
    from_name: false,
    from_email: false,
    from_phone: false,
  });

  const form = useRef();

  const handleChange = (e) => {
    setInputs((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    let isValid = true;

    // Validate each input field
    if (!inputes.from_name) {
      isValid = false;
    }
    if (!inputes.from_email) {
      isValid = false;
    }
    if (!inputes.from_phone) {
      isValid = false;
    }

    if (!isValid) {
      Utility.eToast("Please fill all the details");
      setTouched({
        from_name: true,
        from_email: true,
        from_phone: true,
      });
      return;
    }

    if (isValid) {
      setInputs({
        from_name: "",
        from_email: "",
        from_phone: "",
        from_message: "",
      });
      setTouched({
        from_name: false,
        from_email: false,
        from_phone: false,
      });
      emailjs
        .sendForm(
          "service_48dff64",
          "template_ga8d1bf",
          form.current,
          "9sWEO6LFtdw1HXI-e"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            Utility.sToast("Message sent Successfully");
          },
          (error) => {
            console.log("FAILED...", error.text);
            Utility.eToast("Something wend wrong");
          }
        );
    }
  };

  return (
    <Container className="image-container" id="contactUsId">
      <Card className="community-form-card">
        <Form ref={form} onSubmit={sendEmail}>
          <Row>
            <Col className="readyyyy">Ready to Join </Col>
          </Row>
          <Row>
            <Col className="readyyyy">Our Community?</Col>
          </Row>
          <Row className="long-established-roww">
            <Col className="long-established">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <Form.Group>
                <Form.Label className="last-nameee">Enter name</Form.Label>
                <Form.Control
                  type="text"
                  value={inputes.from_name}
                  name="from_name"
                  placeholder="Enter your full name"
                  className="first-name-plh paddingInMessage"
                  onChange={handleChange}
                />
              </Form.Group>
              <span style={{ color: "red" }}>
                {touched.from_name && inputes.from_name === ""
                  ? "Name is required"
                  : ""}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <Form.Group>
                <Form.Label className="last-nameee">Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={inputes.from_email}
                  name="from_email"
                  placeholder="Enter your email address"
                  className="first-name-plh paddingInMessage"
                  onChange={handleChange}
                />
              </Form.Group>
              <span style={{ color: "red" }}>
                {touched.from_email && inputes.from_email === ""
                  ? "Email is required"
                  : ""}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <Form.Group>
                <Form.Label className="last-nameee">Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  name="from_phone"
                  value={inputes.from_phone}
                  placeholder="Enter your Phone Number"
                  className="first-name-plh paddingInMessage"
                  onChange={handleChange}
                />
              </Form.Group>
              <span style={{ color: "red" }}>
                {touched.from_phone && inputes.from_phone === ""
                  ? "Phone number is required"
                  : ""}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <Form.Group>
                <Form.Label className="last-nameee">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={inputes.from_message}
                  name="from_message"
                  placeholder="Write your Message"
                  className="first-name-plh paddingInMessage textareaInput "
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="message-roww">
            <Col>
              <Button className="message-buttonnn" type="submit">
                Send message
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Container>
  );
}

export default Community;
