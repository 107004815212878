import React from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import DiscoverPopularCourse from "./CodeAiExpertFile/PopularCourse/DiscoverPopularCourse/DiscoverPopularCourse";
import MainComponent from "./CodeAiExpertFile/MainComponent/MainComponent";
import Footer from "./CodeAiExpertFile/Footer/Footer";
import AiNavBar from "./CodeAiExpertFile/AiNavBar/AiNavBar";
import { ToastContainer } from "react-toastify";
import Privacy from "./CodeAiExpertFile/FooterFile/Privacy";
import Terms from "./CodeAiExpertFile/FooterFile/Terms";
import Cancellation from "./CodeAiExpertFile/FooterFile/Cancellation";
import Shipping from "./CodeAiExpertFile/FooterFile/Shipping";

const App = () => {
  const location = useLocation(); // Hook to access the current route

  // List of routes where the header and footer should not be displayed
  const noHeaderFooterRoutes = ["/privacy", "/terms", "/cancellation", "/shipping","/terms-and-conditions"];

  return (
    <div>
      {/* Conditionally render the navbar and footer */}
      {!noHeaderFooterRoutes.includes(location.pathname) && <AiNavBar />}
      <Routes>
        <Route path="/" element={<MainComponent />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cancellation" element={<Cancellation />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
      </Routes>
      {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
      <ToastContainer />
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
