// import React, { useEffect, useState } from "react";
// import "./AiNavBar.css";
// import { Container } from "react-bootstrap";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import OptimizedImage from "../../GlobalComponent/OptimizedImage/OptimizedImage";
// import newAiLogo from "../../Assets/Homepagelogo/newAiLogo.png";

// const AiNavBar = () => {
//   const [expanded, setExpanded] = useState(false);
//   const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
//   const [visible, setVisible] = useState(true);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 574);

//   const handleScroll = () => {
//     if (!isMobile) {
//       const currentScrollPos = window.scrollY;
//       setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
//       setPrevScrollPos(currentScrollPos);
//     }
//   };

//   const handleResize = () => {
//     setIsMobile(window.innerWidth <= 574);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//       window.removeEventListener("resize", handleResize);
//     };
//   }, [prevScrollPos, isMobile]);

//   const handleScrollDown = (elementId) => {
//     const element = document.getElementById(elementId);
//     if (element) {
//       element.scrollIntoView({ behavior: "smooth" });
//     }
//     setExpanded(false);
//   };

//   const toggleExpanded = () => {
//     setExpanded(!expanded);
//   };

//   const handleRegisterClick = (e) => {
//     e.preventDefault();
//     const url =
//       "https://docs.google.com/forms/d/e/1FAIpQLSckbuAnTomnHUAQBlfroaHASRfotzOIZLEEFiWShC9_vIknYw/viewform?usp=sf_link";

//     if (isMobile) {
//       window.location.href = url;
//     } else {
//       window.open(url);
//     }
//   };

//   return (
//     <Navbar
//       collapseOnSelect
//       expand="sm"
//       className={`mainNavBarContainer ${!isMobile && visible ? "show" : ""} ${
//         isMobile ? "mobile" : ""
//       }`}
//       expanded={expanded}
//     >
//       <Container>
//         <Navbar.Brand href="#home" className="CodeimageContainer">
//           <OptimizedImage src={newAiLogo} className="navBarImageCodeai" />
//         </Navbar.Brand>
//         <Navbar.Toggle
//           aria-controls="responsive-navbar-nav"
//           onClick={toggleExpanded}
//         />
//         <Navbar.Collapse id="responsive-navbar-nav">
//           <Nav className="mx-auto CodeNavContainttags">
//             <Nav.Link
//               href="#home"
//               className="navContained"
//               onClick={() => handleScrollDown("home")}
//             >
//               Home
//             </Nav.Link>
//             <Nav.Link
//               href="#courses"
//               className="navContained"
//               onClick={() => handleScrollDown("courses")}
//             >
//               Courses
//             </Nav.Link>
//             <Nav.Link
//               href="#success"
//               className="navContained"
//               onClick={() => handleScrollDown("success")}
//             >
//               Success
//             </Nav.Link>
//             <Nav.Link
//               href="#faq"
//               className="navContained"
//               onClick={() => handleScrollDown("faq")}
//             >
//               FAQ
//             </Nav.Link>
//             <Nav.Link
//               href="#contactUsId"
//               className="navContained"
//               onClick={() => handleScrollDown("contactUsId")}
//             >
//               Contact US
//             </Nav.Link>
//           </Nav>
//           <div className="buttonsnavbarConatiner">
//             {/* <button className="FirstbuttonNavBar">Login</button> */}
//             <button
//               className="SecondbuttonNavBar"
//               onClick={handleRegisterClick}
//             >
//               Register
//             </button>
//           </div>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default AiNavBar;

import React, { useEffect, useState } from "react";
import "./AiNavBar.css";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import OptimizedImage from "../../GlobalComponent/OptimizedImage/OptimizedImage";
import newAiLogo from "../../Assets/Homepagelogo/newAiLogo.png";

const AiNavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 574);

  const handleScroll = () => {
    if (!isMobile) {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 574);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [prevScrollPos, isMobile]);

  const handleScrollDown = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setExpanded(false);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleRegisterClick = (e) => {
    e.preventDefault();
    const url =
      "https://docs.google.com/forms/d/e/1FAIpQLSckbuAnTomnHUAQBlfroaHASRfotzOIZLEEFiWShC9_vIknYw/viewform?usp=sf_link";

    console.log("Button is presses");
    window.open(url, "_blank");
  };

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      className={`mainNavBarContainer ${!isMobile && visible ? "show" : ""} ${
        isMobile ? "mobile" : ""
      }`}
      expanded={expanded}
    >
      <Container className="navContainer">
        <Navbar.Brand href="#home" className="CodeimageContainer">
          <OptimizedImage src={newAiLogo} className="navBarImageCodeai" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={toggleExpanded}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto CodeNavContainttags">
            <Nav.Link
              href="#home"
              className="navContained"
              onClick={() => handleScrollDown("home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#courses"
              className="navContained"
              onClick={() => handleScrollDown("courses")}
            >
              Courses
            </Nav.Link>
            <Nav.Link
              href="#success"
              className="navContained"
              onClick={() => handleScrollDown("success")}
            >
              Success
            </Nav.Link>
            <Nav.Link
              href="#faq"
              className="navContained"
              onClick={() => handleScrollDown("faq")}
            >
              FAQ
            </Nav.Link>
            <Nav.Link
              href="#contactUsId"
              className="navContained"
              onClick={() => handleScrollDown("contactUsId")}
            >
              Contact US
            </Nav.Link>
          </Nav>
          <div className="buttonsnavbarConatiner">
            {/* <button className="FirstbuttonNavBar">Login</button> */}
            <button
              className="SecondbuttonNavBar"
              onClick={handleRegisterClick}
            >
              Register
            </button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AiNavBar;
