import React from "react";
import "./HomePage.css";
import { Col, Container, Row } from "react-bootstrap";
import boyWithBook from "../../Assets/Homepagelogo/BoyWithBooks.png";
import { IoMdArrowRoundDown } from "react-icons/io";

const HomePage = () => {
  const handleButtonClick = () => {
   
      const link = document.createElement("a");
      link.href = `${process.env.PUBLIC_URL}/pdffiles/pdfdocs.pdf`; // Template literals for constructing URL
      link.download = "brochure.pdf";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.open("https://docs.google.com/forms/d/e/1FAIpQLSckbuAnTomnHUAQBlfroaHASRfotzOIZLEEFiWShC9_vIknYw/viewform?usp=sf_link", "_blank");
  };

  return (
    <>
      <Container id="home">
        <Row>
          <Col sm={8} xs={12}>
            <div className="containttext">
              <h1 className="firtsheadertag">
                Begin Your Data <br /> Science Journey with <br /> Our Expert
                Assistance
              </h1>
              <div className="paragraphContaint">
                AI Innovation, Guaranteed Success
              </div>
              <div className="contact-form-container">
                <button className="downloadbtn" onClick={handleButtonClick}>
                  <IoMdArrowRoundDown />
                  Download Brochure
                </button>
              </div>
            </div>
          </Col>
          <Col sm={4} xs={12} className="imagacolomn">
            <div className="mainimagecontainer">
              <img src={boyWithBook} alt="" className="boyimagecontainer" />
              <div className="course-desc">
                <h5 className="coursedesc">Unlock Your Potential</h5>
                <h5 className="coursedesc">
                  with Our <span className="coursename">Courses</span>
                </h5>
              </div>
            </div>
            <div className="container-small-card">
              <div className="small-screen-button">
                <button className="downloadbtn" onClick={handleButtonClick}>
                  <IoMdArrowRoundDown />
                  Download Brochure
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} xs={12} className="textContaint">
            <h1 className="textContaintItems">
              Our <span className="innertext">Students </span> Trusted by
              Leading Companies
            </h1>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
