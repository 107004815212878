import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Feedback.css";
import FeedbackCard from "./FeedbackCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function FeedbackPage() {
  const Feedbacks = [
    {
      name: "Chinmoyee Mohapatra",
      title: "Software Engineer@ServiceNow",
      image: require("../../Assets/StudentsImage/student-1.jpg"),
      para: "Enrolling in Codeaixpert was transformative for my career as a software engineer. The in-depth knowledge and practical skills I gained were crucial in advancing my programming abilities. I'm truly grateful for the role it played in my success.",
      image1: require("../../Assets/vector.png"),
    },
    {
      name: "Deme Sai Kiran",
      title: "Sr Software Engineer@Samsung",
      image: require("../../Assets/StudentsImage/student-3.jpeg"),
      para: "This course is a gem for aspiring data scientists like myself. It provided a structured approach to learning complex algorithms and statistical techniques, all while emphasizing practical application. The insights gained here have not only boosted my confidence but also opened doors to new career opportunities. Highly recommended for anyone passionate about data-driven decision making.",
      image1: require("../../Assets/vector.png"),
    },
    {
      name: "Priyanka Gour",
      title: "Asst. prof @Vignan University",
      image: require("../../Assets/StudentsImage/student-4.jpeg"),
      para: "I am thoroughly impressed with this course as a data scientist. The curriculum is exceptionally comprehensive, keeping pace with the latest tools and methodologies in the field. Through engaging practical exercises and real-world projects, my analytical skills have been significantly sharpened. This course is indispensable for anyone aiming to excel in the dynamic field of data science.",
      image1: require("../../Assets/vector.png"),
    },
    {
      name: "Harshita Kathuria",
      title: "ML Engineer@One Convergence",
      image: require("../../Assets/StudentsImage/harsita.jpg"),
      para: "As a developer, I can confidently say this course is exceptional. It refreshed my understanding of modern software practices and introduced advanced techniques that have significantly impacted my projects.",
      image1: require("../../Assets/vector.png"),
    },
    {
      name: "Mahitha Kodali",
      title: "Data Scientist@ADP",
      image: require("../../Assets/StudentsImage/moohita.jpg"),
      para: "The Codeaixpert course is a must for any developer. The depth of the curriculum and hands-on projects enriched my knowledge and skills. It’s an invaluable learning experience that has had a lasting impact on my career.",
      image1: require("../../Assets/vector.png"),
    },
  ];

  const [clickedButton, setClickedButton] = useState(null);
  const carouselRef = React.useRef(null);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleClickLeft = () => {
    setClickedButton("left");
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleClickRight = () => {
    setClickedButton("right");
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <>
      <Container fluid className="Feedback-container">
        <Row>
          <Col md={12} className="Feedback-first-col">
            Student <span className="Feedback-first-col-span">Feedback</span>
          </Col>
          <Col md={12} className="Feedback-second-col">
            Below you'll get some fantastic reviews from our students. They also
            told about our future planning.
          </Col>
        </Row>
        <Container className="Feedback-card-container" >
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            showDots={true}
            ref={carouselRef}
            arrows={false}
            className="Feedback-carousel"
          
          >
            {Feedbacks.map((feedback, index) => (
              <FeedbackCard
                key={index}
                name={feedback.name}
                title={feedback.title}
                image={feedback.image}
                para={feedback.para}
                image1={feedback.image1}
              />
            ))}
          </Carousel>

          <div className="feedback-buttons">
            <button
              className={`feedback-arrow-left ${
                clickedButton === "left" ? "clicked" : ""
              }`}
              onClick={handleClickLeft}
            >
              <IoIosArrowBack />
            </button>
            <button
              className={`feedback-arrow-right ${
                clickedButton === "right" ? "clicked" : ""
              }`}
              onClick={handleClickRight}
            >
              <IoIosArrowForward />
            </button>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default FeedbackPage;
